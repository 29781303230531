import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import Seo from "../components/SEO";
import { Link } from "gatsby";
import ContentWrapper from "../components/ContentWrapper";

const MainWrapper = styled.div`
  padding-top: 65px;
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    padding-top: 63px;
  }
  .breadcrumbs {
    background: #ececec;
    padding: 19px 0;
    min-height: 47px;
  }
  .breadcrumbs-list {
    font-size: 14px;
    line-height: 1;
  }
  .breadcrumbs-item {
    float: left;
    margin: -2px 6px 0 0;
    &:last-child {
      &::before {
        content: ">";
        padding: 0 5px 0 0;
      }
    }
  }
  .breadcrumbs-item-link {
    color: #555;
    &:hover {
      text-decoration: underline;
    }
  }
  .company-row {
    display: flex;
    padding: 30px 30px 30px 0;
    border-bottom: 1px solid rgba(43, 84, 106, 0.15);
    @media screen and (max-width: ${props => props.theme.responsive.large}) {
      padding: 25px 25px 25px 0;
    }
    @media screen and (max-width: ${props => props.theme.responsive.small}) {
      padding: 15px 0;
      flex-direction: column;
    }
  }
  .company-row-title {
    width: 32%;
    color: #8491a5;
    font-size: 1rem;
    @media screen and (max-width: ${props => props.theme.responsive.large}) {
      width: 24%;
      font-size: 0.875rem;
    }
    @media screen and (max-width: ${props => props.theme.responsive.small}) {
      width: 100%;
      margin-bottom: 4px;
    }
  }
  .company-row-content {
    display: flex;
    flex-direction: column;
    @media screen and (max-width: ${props => props.theme.responsive.large}) {
      width: 76%;
    }
    @media screen and (max-width: ${props => props.theme.responsive.small}) {
      width: 100%;
    }
    iframe {
      @media screen and (max-width: ${props => props.theme.responsive.large}) {
        width: auto;
      }
    }
  }
`;

const Company = () => {
  return (
    <Layout>
      <Seo title="会社概要" description="株式会社八木造園土木の会社概要ページです。" />
      <MainWrapper>
        <div className="breadcrumbs">
          <ContentWrapper>
            <ol className="breadcrumbs-list">
              <li className="breadcrumbs-item">
                <Link to={`/`} className="breadcrumbs-item-link">ホーム</Link>
              </li>
              <li className="breadcrumbs-item">
                <Link to={`/company`} className="breadcrumbs-item-link">会社概要</Link>
              </li>
            </ol>
          </ContentWrapper>
        </div>
        <ContentWrapper>
          <div className="company-row">
            <p className="company-row-title">商号</p>
            <div className="company-row-content">
              <p>株式会社八木造園土木</p>
            </div>
          </div>
          <div className="company-row">
            <p className="company-row-title">代表取締役</p>
            <div className="company-row-content">
              <p>八木武司</p>
            </div>
          </div>
          <div className="company-row">
            <p className="company-row-title">所在地</p>
            <div className="company-row-content">
              <p>〒629-0154 京都府南丹市八木町柴山坊田60番地</p>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3265.506076971176!2d135.51374231509354!3d35.069085980341605!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x600051f1e3d7a5c5%3A0xa6d44c6cfa69148a!2z5YWr5pyo6YCg5ZyS5Zyf5pyo!5e0!3m2!1sja!2sjp!4v1645611335507!5m2!1sja!2sjp" title="google-map" width="600" height="450" style={{ border: "0", marginTop: "10px" }} allowfullscreen="" loading="lazy"></iframe>
            </div>
          </div>
          <div className="company-row">
            <p className="company-row-title">設立</p>
            <div className="company-row-content">
              <p>昭和45年6月</p>
            </div>
          </div>
          <div className="company-row">
            <p className="company-row-title">事業内容</p>
            <div className="company-row-content">
              <p>土木（一般土木、外構等）・造園（植栽、剪定、除草等）・解体等</p>
            </div>
          </div>
          <div className="company-row">
            <p className="company-row-title">従業員数</p>
            <div className="company-row-content">
              <p>5名</p>
            </div>
          </div>
        </ContentWrapper>
      </MainWrapper>
    </Layout>
  );
};

export default Company;